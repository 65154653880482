<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div>

        <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'intentoDashboard' }" v-if="isIntentoUser">
                <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'customerDashboard' }" v-if="isIntentoSales">
                Customer Management
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'customerSites' }" v-if="isUserAuthorized">
                {{ customer.company }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'siteLicenses' }">
                {{ site.name }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'license' }">
                License #{{ license.id }}
            </b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-title>
                            License #{{ license.id }} - {{license.label}}
                        </b-card-title>
                        <b-card-body>

                            <b-row>

                                <b-col>

                                    <strong>Code:</strong> {{ license.code }}
                                    <br />
                                    <strong>Type:</strong> {{ license.type }}
                                    <br />
                                    <strong>Active </strong> <b-icon-check-circle variant="success" v-if="license.active" /> <b-icon-x-circle variant="danger" v-else />

                                </b-col>

                                <b-col>

                                    <strong>Billing:</strong> {{ license.billingFrequency }}
                                    <br />
                                    <strong>Start date:</strong> {{ toLocaleDate(license.startDate) }}
                                    <br />
                                    <strong>End date:</strong> {{ toLocaleDate(license.endDate) }}
                                    <br />
                                    <strong>Track Dynamics </strong>
                                        <b-icon-check-circle variant="success" v-if="license.trackDynamics" />
                                        <b-icon-x-circle variant="danger" v-else />

                                </b-col>

                                <b-col>
                                    <b-button v-if="isIntentoSales"
                                              block
                                              variant="outline-primary"
                                              v-b-modal.modal-edit-license
                                              @click.prevent="populateEditModel()">
                                        <b-icon-pencil />Edit License
                                    </b-button>
                                </b-col>

                            </b-row>

                            <ExerciseStatistics :customerId="customerId" :siteId="siteId" :licenseId="licenseId" />

                        </b-card-body>
                    </b-card>
                </b-col>

            </b-row>

        </b-container>

        <b-modal id="modal-edit-license"
                 @ok="saveLicense"
                 ok-title="Save License"
                 :title="(licenseEditModel.id ? 'Edit License ID#' + licenseEditModel.id : 'New License')">
            <form @submit.prevent="saveLicense">
                <b-form-group label="Label">
                    <b-form-input type="text" v-model="licenseEditModel.label"></b-form-input>
                </b-form-group>
                <b-form-group label="Billing Type">
                    <b-form-select v-model="licenseEditModel.type" :options="licenseTypes" />
                </b-form-group>
                <b-form-group label="Billing Frequency">
                    <b-form-select v-model="licenseEditModel.billingFrequency" :options="billingTypes" />
                </b-form-group>
                <b-form-group label="Start date">
                    <b-form-input type="date" v-model="licenseEditModel.startDate"></b-form-input>
                </b-form-group>
                <b-form-group label="End date">
                    <b-form-input type="date" v-model="licenseEditModel.endDate"></b-form-input>
                </b-form-group>
                <b-form-group label="Usage limit">
                    <b-form-input type="number" v-model.number="licenseEditModel.usageLimit"></b-form-input>
                </b-form-group>
                <b-form-checkbox v-model="licenseEditModel.active" :value="true" :unchecked-value="false" >
                    Active
                </b-form-checkbox>
                <b-form-checkbox v-model="licenseEditModel.trackDynamics" :value="true" :unchecked-value="false" >
                  Track Dynamics
                </b-form-checkbox>
            </form>
            <div class="form-group">
                <div v-if="errorMessage" class="alert alert-danger" role="alert">
                    {{ errorMessage }}
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { formatDateToLocaleString, formatDateToISOString, formatSeconds } from '@/components/utils.js';
    import ApiService from '@/services/api.service';
    import ExerciseStatistics from '@/components/ExerciseStatistics';

    export default {
        name: "License",
        components: { ExerciseStatistics },
        props: {

            licenseId: {
                type: [String,Number],
                required: true
            },
            customerId: {
                type: String,
                required: true
            },
            siteId: {
                type: String,
                required: true
            }
        
        },
        data() {
            return {
                loading: false,
                license: { startDate : 'January 1, 2020 00:00:00', endDate : 'January 1, 2020 00:00:00' },
                licenseEditModel: { startDate : 'January 1, 2020 00:00:00 ', endDate : 'January 1, 2020 00:00:00' },
                licenseTypes: [
                    { value: 'Time', text: 'By date range' },
                    { value: 'Standalone', text: 'Standalone' },
                    { value: 'Session', text: 'By number of sessions' },
                    { value: 'Stimulation', text: 'By stimulation active time' }
                ],
                billingTypes: [
                    { value: 'Manual', text: 'Manual billing' },
                    { value: 'Weekly', text: 'Weekly' },
                    { value: 'Monthly', text: 'Monthly' },
                    { value: 'Quarterly', text: 'Quarterly' },
                    { value: 'Annually', text: 'Annually' }
                ],
                customer: {},
                site : {},
                errorMessage: ''
            };
        },
        async created() {
            this.refreshLicense();
            this.getCustomerAndSiteData();
        },
        computed: {
            ...mapGetters('auth', ['isIntentoSales', 'isIntentoUser', 'isCustomerUser', 'getCustomerIdOfAccountManager'])
        },
        methods: {
            isUserAuthorized() {
                return this.isIntentoSales || 
                    (this.isCustomerUser && this.customerIdOfAccountManager == this.$parent.customerId)
            },
            async refreshLicense() {

                this.loading = true;

                this.license = await ApiService.getLicense(this.licenseId);
                
                this.licenseEditModel = this.license
                this.licenseEditModel.startDate = this.formatDateForInputField(this.licenseEditModel.startDate)
                this.licenseEditModel.endDate = this.formatDateForInputField(this.licenseEditModel.endDate)

                this.loading = false;
               
            },
            async saveLicense(event) {
                event.preventDefault();
                this.licenseEditModel.status = this.licenseEditModel.active ? "Active" : "Inactive";
                await ApiService.updateLicense(this.licenseId, this.licenseEditModel).then(
                            () => { 
                                this.license = Object.assign({}, this.licenseEditModel);
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-license");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
            },
            populateEditModel() {
                this.licenseEditModel = Object.assign({}, this.license);
                this.errorMessage = '';
            },
            async getCustomerAndSiteData() {
                if (this.isUserAuthorized) {
                this.customer = await ApiService.getCustomerAccount(this.customerId);
                }
                this.site = await ApiService.getSite(this.siteId);
            }, 
            toLocaleDate(d) {
                return formatDateToLocaleString(d);
            },
            formatDateForInputField(d) {
                return formatDateToISOString(d);
            },
            getActiveStimulationTimeString(seconds) {
                return formatSeconds(seconds);
            }
        }
    };
</script>

<style scoped>
    .active {
        background: #20C997 !important;
        color: white !important;
    }
</style>
